import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../api.service';
import { LOGIN_SESSION_KEY } from '../../shared/constants/constants';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  googleClient: any;
  deferredPrompt: any;

  constructor(private router: Router, private apiService: ApiService) {
    this.loadGoogleApi();
    this.setupBeforeInstallPromptListener();
  }

  private setupBeforeInstallPromptListener() {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the PWA install prompt');
        } else {
          console.log('User dismissed the PWA install prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }

  loadGoogleApi() {
    this.googleClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/calendar.readonly',
      ux_mode: 'popup',
      callback: (response: { code: string }) => {
        this.exchangeCodeForTokens(response.code);
      },
    });
  }

  async exchangeCodeForTokens(code: string) {
    const clientId = environment.GOOGLE_CLIENT_ID;
    const clientSecret = environment.GOOGLE_CLIENT_SECRET;
    const redirectUri = 'postmessage';
    const grantType = 'authorization_code';

    const params = new URLSearchParams();
    params.append('code', code);
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);
    params.append('redirect_uri', redirectUri);
    params.append('grant_type', grantType);

    try {
      const response = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'User-Agent': 'web'
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const g_csrf_token = this.generateRandomString();
      this.signIn(g_csrf_token, data.id_token);
    } catch (error) {
      console.error('Error fetching credentials:', error);
    }
  }

  generateRandomString() {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.setCookie('g_csrf_token', result, 1);

    return result;
  }

  setCookie(name: string, value: any, days: number) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  signIn(token: string, credential: string) {
    this.apiService
      .loginGoogle({ g_csrf_token: token, credential: credential })
      .subscribe(
        (response) => {
          localStorage.setItem(LOGIN_SESSION_KEY, response.data.token);
          this.router.navigate(['/main']);
        },
        (error) => {
          console.error('Ошибка аутентификации', error);
        }
    );
  }

  loginGoogle() {
    this.googleClient.requestCode();
  }
}
